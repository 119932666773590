module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/src/plugins/gatsby-remark-static-tweet/index.js","options":{"apiToken":"Bearer AAAAAAAAAAAAAAAAAAAAAGsZPwEAAAAAkQwC3%2FlOeKDKRzJZ%2F3oZP%2BZxmPw%3DQ19miezm2fI2kRdcYafhB9jhplSVOvtSFbfjrwxLq8wthYM9vQ"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"withWebp":true,"withAvif":true,"useMozJpeg":true}},{"resolve":"/opt/build/repo/src/plugins/gatsby-remark-autolink-header/index.js"},{"resolve":"/opt/build/repo/src/plugins/gatsby-remark-prismjs-title/index.js"},{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":"›"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-external-links","options":{"target":null,"rel":"noopener noreferrer"}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pixels with purpose - a blog by Viral Ganatra","short_name":"Pixels with purpose","start_url":"/","background_color":"#c75a1f","theme_color":"#ffffff","display":"standalone","icon":"content/assets/favicon-512x512.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0BZC4JSTTY"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
